import "../styles/signup-dfy.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { loadStripe } from "@stripe/stripe-js"
import { Link, navigate, graphql } from "gatsby"
import { Helmet } from "react-helmet"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"
// import Img from "gatsby-image"
import {
  CardElement,
  //   CardNumberElement,
  //   CardExpiryElement,
  //   CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import GoTrue from "gotrue-js"
import firebase from "gatsby-plugin-firebase"
import Header from "../components/TealHeader"
import { StaticImage } from "gatsby-plugin-image"
// import { useFirebaseArsenal, useAuth } from "../lib/firebase/firebaseCentral"

const StripeCard = ({
  name,
  email,
  profile,
  password,
  firebase,
  setPaymentComplete,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])

  const [processing, setProcessing] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)

  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])

  const getSubscriptions = async () => {
    if (email && firebase) {
      //   const subs = await firebase.listSubscriptions({ userId: email })
      const listSubscriptionsCallable = await firebase
        .functions()
        .httpsCallable("listSubscriptions")
      const subs = await listSubscriptionsCallable({
        userId: profile.email,
      })
      // console.log("subs", subs);
      setSubscriptions(subs.data.data)
    }
  }

  // Cancel subscription
  const cancelSubscription = async id => {
    setProcessing(true)
    const cancelSubscriptionCallable = await firebase
      .functions()
      .httpsCallable("cancelSubscription")
    await cancelSubscriptionCallable({
      userId: profile.email,
      subscriptionId: id,
    })
    // await firebase.cancelSubscription({
    //   userId: email,
    //   subscriptionId: id,
    // })
    toast.success("Your previous subscription has been canceled!")
    await getSubscriptions() // rehydrate subscriptions
    setProcessing(false)
  }

  const handleSubmit = async event => {
    // event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement("card").focus()
      return
    }

    // if (cardComplete) {
    //   setProcessing(true)
    // }
    setProcessing(true)

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: { email: profile.email, name: profile.name },
    })

    // setProcessing(false)

    if (payload.error) {
      setError(payload.error)
      // console.log("payload.error", payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod)
      //   console.log("payload", payload)
      //   console.log("{name,email,password}", { name, email, password })
    }

    try {
      // Create Subscription on the Server
      const createSubscriptionCallable = await firebase
        .functions()
        .httpsCallable("createSubscription")

      //   console.log("[...]", [email, payload.paymentMethod.id, name])
      const subscription = await createSubscriptionCallable({
        userId: profile.email,
        name: profile.name,
        plan: process.env.GATSBY_PUBLIC_STRIPE_INHOUSE_PRO,
        //   paymentMethod: wallet?.[0]?.id
        paymentMethod: payload.paymentMethod.id,
      })

      const { latest_invoice } = subscription.data

      // console.log("subscription.data", subscription);

      // At this point, if there was an error with the initial invoice/payment intent,
      // then no updates to firestore would have taken place.
      // The below error handling is one way we can let the user know
      // the reason as to why their payment did not go through.
      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent
        // stripe will attempt to make a charge
        if (status === "requires_action") {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          )
          if (confirmationError) {
            // console.error(confirmationError);
            alert("unable to confirm card")
            return
          }
        }

        /*
         * If we only want 1 subscription active at a time, then also cancel current subscription *
         */
        // if (subscriptions.length > 0) {
        //   cancelSubscription(subscriptions?.[0]?.id)
        // }

        // success
        //   alert('You are subscribed!');
        toast.success("You are subscribed!")
        // getSubscriptions()

        // change state
        setPaymentComplete(true)
      }

      setProcessing(false)
    } catch (err) {
      setProcessing(false)
      // console.log("err", err);
      toast.error(`unsuccessful: ${err}`)
    }
  }

  return (
    // <form onSubmit={handleSubmit} className="register__card-container">
    <div className="register__card-container">
      <div className="register__card-heading">Add Card Details</div>
      <div className="register__card">
        {/* <CardElement style={{ color: "#fff" }} /> */}
        {/* <CardNumberElement */}
        <CardElement
          //  onChange={onChange}
          options={{
            iconStyle: "solid",
            style: {
              base: {
                // iconColor: "#c4f0ff",
                color: "#272727",
                fontWeight: 500,
                fontFamily: "Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                autoFill: "#fff",
                ":-webkit-autofill": {
                  color: "#fff",
                },
                "::placeholder": {
                  color: "#fff",
                },
              },
              invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee",
              },
            },
          }}
        />
      </div>
      <button
        // style={{ backgroundColor: `${processing ? "#333" : "#272727"}` }}
        disabled={
          processing ||
          !stripe ||
          profile?.activePlans?.includes(
            process.env.GATSBY_PUBLIC_STRIPE_INHOUSE_PRO
          )
        }
        onClick={handleSubmit}
        className="register__confirm"
      >
        {processing
          ? "Processing"
          : profile?.activePlans?.includes(
              process.env.GATSBY_PUBLIC_STRIPE_INHOUSE_PRO
            )
          ? "Already Purchased"
          : "Confirm"}
      </button>
    </div>
  )
}

export default function DFYBasic() {
  const [user, setUser] = useState(null)
  const [identity, setIdentity] = useState(null)

  const [step, setStep] = useState("two")
  const [stripeObj, setStripeObj] = useState({})
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [accountDetails, setAccountDetails] = useState({
    name: "",
    email: "",
    password: "",
  })

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })
    // console.log("auth", auth);
    setIdentity(auth)
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setUser(userData)
      }
    }
  }, [identity])

  // useEffect(() => {
  //   if (!profile?.email) {
  //     navigate("/dfy-pro")
  //     toast.success("You must already have an account. Redirecting!")
  //   }
  // }, [profile])

  useEffect(() => {
    let stripePromise
    const getStripe = () => {
      if (!stripePromise) {
        stripePromise = loadStripe(
          "pk_test_51JPrYxJ1tYytnqOaHXCvc4aK8UD2tBQ5mLCkQnXXRrkNW25MPpANcH8gtl3fM2R3YrQXk6TNzQzdEipkYRzdGsxJ00MnD6g6Df"
        )
      }
      return stripePromise
    }

    async function handleStripe() {
      const stripe = await getStripe().catch(err => console.warn("err", err))
      if (stripe) {
        // console.log("stripe", stripe)
        setStripeObj(stripe)
      }
    }

    handleStripe()
  }, [])

  // console.log("stripeObj", Object.keys(stripeObj).length > 0)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm()

  const onSubmitAccountDetails = data => {
    // console.log(data)
    // console.log("getValues", getValues())

    setAccountDetails(getValues())
    setStep("two")
  }
  // console.log("user", user);

  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Done For You | Pro</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <div className="package">
        <>
          <div className="dfy-img-left">
            {/* <Img fluid={data.one.childImageSharp.fluid} /> */}
            <StaticImage src="../images/abstract_1.png" />
          </div>
          <div className="dfy-img-right">
            <StaticImage src="../images/abstract_2.png" />
            {/* <Img fluid={data.two.childImageSharp.fluid} /> */}
          </div>
        </>

        {/* {step === "two" && ( */}
        {(step === "two" || user?.email) && !paymentComplete && (
          <>
            <form
              className="register"
              onSubmit={handleSubmit(onSubmitAccountDetails)}
            >
              {user?.email ? (
                <div
                  style={{ justifyContent: "center" }}
                  className="register__header"
                >
                  {user?.activePlans?.includes(
                    process.env.GATSBY_PUBLIC_STRIPE_INHOUSE_PRO
                  ) ? (
                    <div>Already Purchased</div>
                  ) : (
                    <div>Payment Details</div>
                  )}
                </div>
              ) : (
                <div className="register__header">
                  <div>Payment Details</div>
                </div>
              )}
              {/* <div
                style={{ justifyContent: "center" }}
                className="register__header"
              >
                <div>Payment Details</div>
              </div> */}
              <div className="register__top-content">
                <div className="register__text">Total price per month</div>
                <div className="register__price">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 616 158"
                  >
                    <path
                      class="cls-1"
                      d="M502.66 301.13c0-20.19-15.4-28.53-29.49-33.58l-12.76-4.83c-9-3.07-11.88-7.24-11.88-12.07 0-5.48 4.62-9.87 12.32-9.87 6.16 0 12.1 2.85 15.19 12.07l23.1-5.93c-3.08-14.48-13.86-25-29.27-28.09v-19.75H451v19.32c-17.6 2.85-31 14.48-31 32 0 13.83 7.92 25.9 26.84 32.7l14.08 5.27c9 3.29 12.54 7.24 12.54 12.73 0 8.12-7.26 11.85-14.74 11.85-11.22 0-16.72-7.68-18.92-15.15l-23.54 6.37c3.08 18.43 16.28 29.18 34.76 31.6v19.09h18.92v-19.5c18.64-3.29 32.72-14.92 32.72-34.23zM522.76 354.89H552l63.59-155.81H514.83v25.68h61.61zM682.85 196.89c-27.73 0-52.81 18.87-52.81 50.25 0 29 21.12 48.06 45.54 48.06a50.64 50.64 0 009.91-1.1L641 354.89h33.44l37.19-51.57c10.12-13.61 23.54-30.94 23.54-55.08.04-32.48-24.6-51.35-52.32-51.35zm-.22 73.51c-14.75 0-24.65-10.53-24.65-23.92s9.9-23.92 24.65-23.92c14.52 0 24.42 10.54 24.42 23.92s-9.9 23.92-24.42 23.92zM803.82 196.89c-27.72 0-52.81 18.87-52.81 50.25 0 29 21.12 48.06 45.55 48.06a50.54 50.54 0 009.9-1.1L762 354.89h33.45l37.18-51.57c10.12-13.61 23.55-30.94 23.55-55.08.01-32.48-24.64-51.35-52.36-51.35zm-.22 73.51c-14.74 0-24.64-10.53-24.64-23.92s9.9-23.92 24.64-23.92c14.52 0 24.42 10.54 24.42 23.92s-9.9 23.92-24.42 23.92zM890.49 287.89a9.18 9.18 0 10-9.3-9.18 9.09 9.09 0 009.3 9.18zM937 200.89c-15.23 0-29 10.25-29 27.29 0 15.73 11.6 26.1 25 26.1a28.84 28.84 0 005.44-.59L914 286.7h18.36l20.43-28c5.56-7.39 12.93-16.8 12.93-29.91.03-17.65-13.51-27.9-28.72-27.9zm-.13 39.92c-8.09 0-13.53-5.72-13.53-13s5.44-13 13.53-13 13.42 5.72 13.42 13-5.45 13-13.43 13zM1003.43 200.89c-15.23 0-29 10.25-29 27.29 0 15.73 11.6 26.1 25 26.1a28.84 28.84 0 005.44-.59l-24.41 33h18.36l20.43-28c5.56-7.39 12.93-16.8 12.93-29.91.01-17.64-13.53-27.89-28.75-27.89zm-.12 39.92c-8.1 0-13.54-5.72-13.54-13s5.44-13 13.54-13 13.41 5.72 13.41 13-5.44 13-13.41 13z"
                      transform="translate(-416.19 -196.89)"
                    />
                  </svg>
                </div>
                <div className="register__text">
                  7-day, risk-free, money-back guarantee!
                </div>
              </div>

              {Object.keys(stripeObj).length > 0 &&
                firebase &&
                user?.email &&
                !user?.activePlans?.includes(
                  process.env.GATSBY_PUBLIC_STRIPE_INHOUSE_PRO
                ) && (
                  <Elements stripe={stripeObj}>
                    <StripeCard
                      profile={user || null}
                      firebase={firebase}
                      setPaymentComplete={setPaymentComplete}
                    />
                  </Elements>
                )}

              {/* <button type="submit" className="register__confirm">
                Confirm
              </button> */}
            </form>
          </>
        )}

        {paymentComplete && (
          <div className="register">
            <div
              style={{ justifyContent: "center", width: "100%" }}
              className="register__header"
            >
              <div>
                {/* <b>Purchase Complete</b> */}
                Purchase Complete
              </div>
              {/* <div>Create Account</div> */}
            </div>
            <div
              style={{ alignItems: "center" }}
              className="register__container"
            >
              <div className="register__purchased-header">Let's do this</div>
              <div className="register__purchased-header">
                {user?.name}
                {user?.name ? "!" : ""}
              </div>

              <div className="register__purchased-p">
                Thank you for allowing us to help you supercharge your business
                forward.
              </div>
              <div className="register__purchased-p">
                For instructions on what to do next, you can expect an email{" "}
                {accountDetails?.email ? `sent to` : ""}{" "}
                <span className="register__purchased-highlight">
                  {accountDetails?.email ? `${accountDetails.email}` : ""}
                </span>{" "}
                from{" "}
                <span className="register__purchased-highlight">
                  {" "}
                  hello@berserkers.dev
                </span>{" "}
                within the hour. Be sure to check your spam folder, updates
                folder, promotions folder, etc.
              </div>
              <div className="register__purchased-p">
                If you don’t see anything after an hour, please reach out at
                <span className="register__purchased-highlight">
                  {" "}
                  hello@berserkers.dev
                </span>{" "}
                and we’ll sort it out.
              </div>
              <div className="register__purchased-p">
                To manage your subscriptions, you can navigate to your{" "}
                <Link to="/dashboard">dashboard</Link>
              </div>
              <div className="register__purchased-p">
                For login, please enter the email and password you used during
                this registration process.
              </div>
              <div className="register__purchased-p">
                Now let's grow your business!
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
